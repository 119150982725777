<template>
    <v-container>
        <v-row class="mt-3">
            <v-col align="center" justify="center" class="mx-auto" cols="12" sm="8">
                <v-col align="start" justify="start">
                    <v-btn class="mb-3" color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card class="pa-3" elevation-1>
                    <v-toolbar class="white--text text-h5 mb-4" color="secondary">
                        Select team members
                    </v-toolbar>
                    <div class="pa-4">
                        <v-autocomplete
                        v-model="salespersons"
                        outlined 
                        dense
                        filled
                        small-chips
                        deletable-chips
                        :items="salespersonOptions"
                        label="Salesperson name"
                        multiple>
                        </v-autocomplete>
                        <div class="d-flex justify-end">
                            <v-btn color="primary" @click="clearSalesperson" plain>
                                Clear
                            </v-btn>
                            <v-btn color="primary">
                                Confirm
                            </v-btn>
                        </div>
                    </div>

                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default{
    data:()=>({
        salespersons:[],
        salespersonOptions : ['salespersonone','salespersontwo','salespersonthree','four','five','six','seven','eight','nine','ten'],
    }),
    methods:{
        clearSalesperson(){
            this.salespersons = null;
        }
        
    }
}
</script>